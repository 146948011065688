export const SvgLogo = (props: any) => (
  <svg
    width="140"
    height="40"
    viewBox="0 0 142 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_1504_1703)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3036 38.656C30.4394 38.656 38.656 30.4394 38.656 20.3036C38.656 10.1678 30.4394 1.95119 20.3036 1.95119C10.1678 1.95119 1.95119 10.1678 1.95119 20.3036C1.95119 30.4394 10.1678 38.656 20.3036 38.656ZM20.3036 39.6072C30.9647 39.6072 39.6072 30.9647 39.6072 20.3036C39.6072 9.64252 30.9647 1 20.3036 1C9.64252 1 1 9.64252 1 20.3036C1 30.9647 9.64252 39.6072 20.3036 39.6072Z"
        fill="url(#paint0_linear_1504_1703)"
      />
    </g>
    <g filter="url(#filter1_f_1504_1703)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8632 39.7146C31.9989 39.7146 40.2156 31.498 40.2156 21.3622C40.2156 11.2264 31.9989 3.00979 21.8632 3.00979C11.7274 3.00979 3.51076 11.2264 3.51076 21.3622C3.51076 31.498 11.7274 39.7146 21.8632 39.7146ZM21.8632 40.6658C32.5243 40.6658 41.1668 32.0233 41.1668 21.3622C41.1668 10.7011 32.5243 2.05859 21.8632 2.05859C11.2021 2.05859 2.55957 10.7011 2.55957 21.3622C2.55957 32.0233 11.2021 40.6658 21.8632 40.6658Z"
        fill="url(#paint1_linear_1504_1703)"
      />
    </g>
    <g filter="url(#filter2_f_1504_1703)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8046 40.0486C30.9403 40.0486 39.157 31.832 39.157 21.6962C39.157 11.5604 30.9403 3.34377 20.8046 3.34377C10.6688 3.34377 2.45217 11.5604 2.45217 21.6962C2.45217 31.832 10.6688 40.0486 20.8046 40.0486ZM20.8046 40.9998C31.4657 40.9998 40.1082 32.3573 40.1082 21.6962C40.1082 11.0351 31.4657 2.39258 20.8046 2.39258C10.1435 2.39258 1.50098 11.0351 1.50098 21.6962C1.50098 32.3573 10.1435 40.9998 20.8046 40.9998Z"
        fill="url(#paint2_linear_1504_1703)"
      />
    </g>
    <path
      d="M60.5244 27.9276C63.903 27.9276 65.919 25.7602 66.5163 23.4986L64.463 22.8202C64.015 24.3279 62.7457 25.7602 60.5244 25.7602C58.2657 25.7602 56.1378 24.1017 56.1378 20.9732C56.1378 17.7316 58.3217 16.1297 60.5057 16.1297C62.727 16.1297 63.959 17.4301 64.351 19.0509L66.4603 18.3347C65.863 15.9977 63.8657 14 60.5057 14C57.0711 14 53.8418 16.6197 53.8418 20.9732C53.8418 25.3268 56.9591 27.9276 60.5244 27.9276Z"
      fill="#020217"
    />
    <path d="M69.926 27.6449V14H67.7607V27.6449H69.926Z" fill="#020217" />
    <path
      d="M75.8744 25.9298C74.549 25.9298 73.3357 24.9121 73.3357 23.0086C73.3357 21.1051 74.549 20.1251 75.8744 20.1251C77.2183 20.1251 78.413 21.1051 78.413 23.0086C78.413 24.931 77.2183 25.9298 75.8744 25.9298ZM75.8744 18.1462C73.1677 18.1462 71.1704 20.2005 71.1704 23.0086C71.1704 25.8356 73.1677 27.8899 75.8744 27.8899C78.5997 27.8899 80.597 25.8356 80.597 23.0086C80.597 20.2005 78.5997 18.1462 75.8744 18.1462Z"
      fill="#020217"
    />
    <path
      d="M87.852 26.6209C87.852 26.9978 87.8893 27.4313 87.9266 27.6386H89.9986C89.9613 27.2994 89.9053 26.7151 89.9053 25.9612V18.4603H87.74V23.8881C87.74 25.0943 87.0866 25.9424 85.836 25.9424C84.5293 25.9424 84.0067 24.9812 84.0067 23.8504V18.4603H81.8414V24.2462C81.8414 26.2816 83.1107 27.9024 85.276 27.9024C86.34 27.9024 87.404 27.4501 87.852 26.6209Z"
      fill="#020217"
    />
    <path
      d="M93.4083 23.0275C93.4083 21.2748 94.4536 20.1628 95.8723 20.1628C97.2909 20.1628 98.2616 21.2559 98.2616 23.0086C98.2616 24.7614 97.2723 25.9487 95.8536 25.9487C94.379 25.9487 93.4083 24.7802 93.4083 23.0275ZM100.352 14H98.2429V19.5409C97.9629 18.9566 97.1603 18.2216 95.5736 18.2216C93.0163 18.2216 91.243 20.389 91.243 23.0275C91.243 25.7979 92.9977 27.871 95.611 27.871C96.8989 27.871 97.8509 27.2302 98.2803 26.4575C98.2803 26.8345 98.3363 27.4376 98.3736 27.6449H100.446C100.427 27.4941 100.352 26.7968 100.352 25.9675V14Z"
      fill="#020217"
    />
    <path
      d="M104.334 27.6512V22.2045C104.334 21.036 105.062 20.1691 106.22 20.1691C107.452 20.1691 107.974 20.9983 107.974 22.0537V27.6512H110.102V22.2045C110.102 21.0737 110.849 20.1691 111.988 20.1691C113.201 20.1691 113.742 20.9795 113.742 22.0537V27.6512H115.833V21.6957C115.833 19.2645 114.265 18.2091 112.622 18.2091C111.446 18.2091 110.382 18.6237 109.673 19.8299C109.188 18.7556 108.142 18.2091 106.948 18.2091C105.921 18.2091 104.782 18.6991 104.26 19.6414V18.4729H102.188V27.6512H104.334Z"
      fill="#020217"
    />
    <path
      d="M117.077 25.1194C117.077 26.5706 118.253 27.8899 120.157 27.8899C121.632 27.8899 122.528 27.136 122.957 26.4387C122.957 27.1737 123.032 27.5506 123.051 27.6072H125.067C125.048 27.5129 124.955 26.9664 124.955 26.1372V21.5763C124.955 19.7482 123.891 18.1462 121.091 18.1462C118.851 18.1462 117.432 19.5597 117.264 21.1428L119.243 21.5951C119.336 20.6717 119.971 19.9366 121.109 19.9366C122.304 19.9366 122.827 20.5586 122.827 21.3313C122.827 21.6517 122.677 21.9155 122.155 21.9909L119.821 22.349C118.272 22.5752 117.077 23.4798 117.077 25.1194ZM120.568 26.1748C119.709 26.1748 119.243 25.6094 119.243 24.9875C119.243 24.2337 119.784 23.8567 120.456 23.7436L122.827 23.3856V23.8002C122.827 25.5906 121.781 26.1748 120.568 26.1748Z"
      fill="#020217"
    />
    <path
      d="M129.64 15.6334H127.68V17.0092C127.68 17.8196 127.25 18.4415 126.28 18.4415H125.813V20.3827H127.493V25.0001C127.493 26.7151 128.538 27.7328 130.218 27.7328C131.002 27.7328 131.413 27.582 131.544 27.5255V25.7162C131.45 25.7351 131.096 25.7916 130.797 25.7916C129.994 25.7916 129.64 25.4524 129.64 24.642V20.3827H131.525V18.4415H129.64V15.6334Z"
      fill="#020217"
    />
    <path
      d="M134.512 22.0098C134.586 21.0297 135.37 19.9743 136.733 19.9743C138.226 19.9743 138.936 20.9355 138.973 22.0098H134.512ZM139.216 24.4033C138.917 25.3079 138.245 26.0052 136.938 26.0052C135.594 26.0052 134.512 25.0064 134.456 23.6117H141.101C141.12 23.574 141.157 23.2725 141.157 22.9144C141.157 20.012 139.514 18.1462 136.714 18.1462C134.418 18.1462 132.29 20.0686 132.29 22.9709C132.29 26.0806 134.474 27.8899 136.938 27.8899C139.122 27.8899 140.56 26.5895 141.045 24.9875L139.216 24.4033Z"
      fill="#020217"
    />
    <defs>
      <filter
        id="filter0_f_1504_1703"
        x="0"
        y="0"
        width="40.6074"
        height="40.6074"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.5"
          result="effect1_foregroundBlur_1504_1703"
        />
      </filter>
      <filter
        id="filter1_f_1504_1703"
        x="1.55957"
        y="1.05859"
        width="40.6074"
        height="40.6074"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.5"
          result="effect1_foregroundBlur_1504_1703"
        />
      </filter>
      <filter
        id="filter2_f_1504_1703"
        x="0.500977"
        y="1.39258"
        width="40.6074"
        height="40.6074"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.5"
          result="effect1_foregroundBlur_1504_1703"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1504_1703"
        x1="1.17483"
        y1="17.7249"
        x2="39.4311"
        y2="22.8838"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#69F0D5" />
        <stop offset="0.5" stopColor="#6A6AF0" />
        <stop offset="1" stopColor="#F069D5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1504_1703"
        x1="2.7344"
        y1="18.7835"
        x2="40.9907"
        y2="23.9424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#69F0D5" />
        <stop offset="0.5" stopColor="#6A6AF0" />
        <stop offset="1" stopColor="#F069D5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1504_1703"
        x1="1.6758"
        y1="19.1175"
        x2="39.9321"
        y2="24.2764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#69F0D5" />
        <stop offset="0.5" stopColor="#6A6AF0" />
        <stop offset="1" stopColor="#F069D5" />
      </linearGradient>
    </defs>
  </svg>
);
